/* generated by Svelte v3.7.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";
import { tweened } from "svelte/motion"
import EmoticonList from "./EmoticonList.svelte"
import emoticons from "../../lib/emoticons.json"

function add_css() {
	var style = element("style");
	style.id = 'svelte-1pa4422-style';
	style.textContent = ".wrapper.svelte-1pa4422{padding:0 0 6vw}.header.svelte-1pa4422{text-align:center;padding:2vw 16px 6vw}h1.svelte-1pa4422{font-size:40px;margin-bottom:48px}h2.svelte-1pa4422{font-size:5vw;color:blue}@media(max-width: 768px){h2.svelte-1pa4422{font-size:10vw}}.message.svelte-1pa4422{position:fixed;top:0;left:0;width:100%;height:100%;background:blue;color:#fff;display:flex;align-items:center;justify-content:center;pointer-events:none;text-align:center;font-size:10vw}.input.svelte-1pa4422{width:400px;font:inherit;padding:16px;outline:none;border:2px solid rgb(206, 206, 206);border-radius:8px;max-width:100%}";
	append(document.head, style);
}

function create_fragment(ctx) {
	var div2, div1, h2, t0_value = ctx.randomEmoticon.contents, t0, t1, h1, t3, div0, input, t4, t5, div6, div5, div3, t6, t7, div4, div6_style_value, current, dispose;

	var emoticonlist = new EmoticonList({
		props: {
		emoticons: emoticons,
		search: ctx.searchValue,
		onCopy: ctx.handleCopyEmoji
	}
	});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			h2 = element("h2");
			t0 = text(t0_value);
			t1 = space();
			h1 = element("h1");
			h1.textContent = "ASCII Emoticon Database";
			t3 = space();
			div0 = element("div");
			input = element("input");
			t4 = space();
			emoticonlist.$$.fragment.c();
			t5 = space();
			div6 = element("div");
			div5 = element("div");
			div3 = element("div");
			t6 = text(ctx.showCopyMessage);
			t7 = space();
			div4 = element("div");
			div4.textContent = "COPIED";
			attr(h2, "class", "logo svelte-1pa4422");
			attr(h1, "class", "svelte-1pa4422");
			attr(input, "class", "input svelte-1pa4422");
			attr(input, "type", "text");
			attr(input, "placeholder", "Search");
			attr(div1, "class", "header svelte-1pa4422");
			attr(div2, "class", "wrapper svelte-1pa4422");
			attr(div6, "class", "message svelte-1pa4422");
			attr(div6, "style", div6_style_value = `
    opacity: ${ctx.$messageTween.opacity};
  `);
			dispose = listen(input, "input", ctx.input_input_handler);
		},

		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, h2);
			append(h2, t0);
			append(div1, t1);
			append(div1, h1);
			append(div1, t3);
			append(div1, div0);
			append(div0, input);

			input.value = ctx.searchValue;

			append(div2, t4);
			mount_component(emoticonlist, div2, null);
			insert(target, t5, anchor);
			insert(target, div6, anchor);
			append(div6, div5);
			append(div5, div3);
			append(div3, t6);
			append(div5, t7);
			append(div5, div4);
			current = true;
		},

		p(changed, ctx) {
			if (changed.searchValue && (input.value !== ctx.searchValue)) input.value = ctx.searchValue;

			var emoticonlist_changes = {};
			if (changed.emoticons) emoticonlist_changes.emoticons = emoticons;
			if (changed.searchValue) emoticonlist_changes.search = ctx.searchValue;
			if (changed.handleCopyEmoji) emoticonlist_changes.onCopy = ctx.handleCopyEmoji;
			emoticonlist.$set(emoticonlist_changes);

			if (!current || changed.showCopyMessage) {
				set_data(t6, ctx.showCopyMessage);
			}

			if ((!current || changed.$messageTween) && div6_style_value !== (div6_style_value = `
    opacity: ${ctx.$messageTween.opacity};
  `)) {
				attr(div6, "style", div6_style_value);
			}
		},

		i(local) {
			if (current) return;
			transition_in(emoticonlist.$$.fragment, local);

			current = true;
		},

		o(local) {
			transition_out(emoticonlist.$$.fragment, local);
			current = false;
		},

		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_component(emoticonlist);

			if (detaching) {
				detach(t5);
				detach(div6);
			}

			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $messageTween;

	

  let randomEmoticon = emoticons[Math.floor(Math.random() * emoticons.length)]
  let searchValue = ''

  let showCopyMessage = null
  let messageTween = tweened({
    opacity: 0
  }); component_subscribe($$self, messageTween, $$value => { $messageTween = $$value; $$invalidate('$messageTween', $messageTween) })

  function handleCopyEmoji (emoticon) {
    $$invalidate('showCopyMessage', showCopyMessage = emoticon)
    $messageTween.opacity = 1; messageTween.set($messageTween);
    messageTween.set({
      opacity: 0
    })
  }

	function input_input_handler() {
		searchValue = this.value;
		$$invalidate('searchValue', searchValue);
	}

	return {
		randomEmoticon,
		searchValue,
		showCopyMessage,
		messageTween,
		handleCopyEmoji,
		$messageTween,
		input_input_handler
	};
}

class Component extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1pa4422-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, []);
	}
}

export default Component;