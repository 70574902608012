/* generated by Svelte v3.7.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";
import Fuse from "fuse.js"
import Emoticon from "./Emoticon.svelte"

function add_css() {
	var style = element("style");
	style.id = 'svelte-mqldjt-style';
	style.textContent = ".wrapper.svelte-mqldjt{max-width:1200px;margin:0 auto;padding:0 8px}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = Object.create(ctx);
	child_ctx.emoticon = list[i];
	return child_ctx;
}

// (39:2) {#each searchedEmoticons as emoticon}
function create_each_block(ctx) {
	var current;

	var emoticon = new Emoticon({
		props: {
		emoticon: ctx.emoticon,
		onCopy: ctx.onCopy
	}
	});

	return {
		c() {
			emoticon.$$.fragment.c();
		},

		m(target, anchor) {
			mount_component(emoticon, target, anchor);
			current = true;
		},

		p(changed, ctx) {
			var emoticon_changes = {};
			if (changed.searchedEmoticons) emoticon_changes.emoticon = ctx.emoticon;
			if (changed.onCopy) emoticon_changes.onCopy = ctx.onCopy;
			emoticon.$set(emoticon_changes);
		},

		i(local) {
			if (current) return;
			transition_in(emoticon.$$.fragment, local);

			current = true;
		},

		o(local) {
			transition_out(emoticon.$$.fragment, local);
			current = false;
		},

		d(detaching) {
			destroy_component(emoticon, detaching);
		}
	};
}

function create_fragment(ctx) {
	var div, current;

	var each_value = ctx.searchedEmoticons;

	var each_blocks = [];

	for (var i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
			attr(div, "class", "wrapper svelte-mqldjt");
		},

		m(target, anchor) {
			insert(target, div, anchor);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},

		p(changed, ctx) {
			if (changed.searchedEmoticons || changed.onCopy) {
				each_value = ctx.searchedEmoticons;

				for (var i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(changed, child_ctx);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();
				for (i = each_value.length; i < each_blocks.length; i += 1) out(i);
				check_outros();
			}
		},

		i(local) {
			if (current) return;
			for (var i = 0; i < each_value.length; i += 1) transition_in(each_blocks[i]);

			current = true;
		},

		o(local) {
			each_blocks = each_blocks.filter(Boolean);
			for (let i = 0; i < each_blocks.length; i += 1) transition_out(each_blocks[i]);

			current = false;
		},

		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { emoticons, search, onCopy } = $$props;

  let searchedEmoticons = emoticons

  let fuse = new Fuse(emoticons, {
    threshold: .4,
    tokenize: true,
    keys: [
      'tags',
      'contents'
    ]
  })

	$$self.$set = $$props => {
		if ('emoticons' in $$props) $$invalidate('emoticons', emoticons = $$props.emoticons);
		if ('search' in $$props) $$invalidate('search', search = $$props.search);
		if ('onCopy' in $$props) $$invalidate('onCopy', onCopy = $$props.onCopy);
	};

	$$self.$$.update = ($$dirty = { search: 1, emoticons: 1, fuse: 1 }) => {
		if ($$dirty.search || $$dirty.emoticons || $$dirty.fuse) { {
        if (!search) {
          $$invalidate('searchedEmoticons', searchedEmoticons = emoticons)
        } else {
          $$invalidate('searchedEmoticons', searchedEmoticons = fuse.search(search))
        }
      } }
	};

	return {
		emoticons,
		search,
		onCopy,
		searchedEmoticons
	};
}

class Component extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-mqldjt-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, ["emoticons", "search", "onCopy"]);
	}
}

export default Component;