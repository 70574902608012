/* generated by Svelte v3.7.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";
const { document: document_1 } = globals;

function add_css() {
	var style = element("style");
	style.id = 'svelte-1spak4f-style';
	style.textContent = ".wrapper.svelte-1spak4f{position:relative;display:inline-block;width:33.333%;padding:8px;cursor:pointer}@media(hover: hover){.wrapper.svelte-1spak4f:hover .hover.svelte-1spak4f{opacity:1}.wrapper.svelte-1spak4f:hover .content.svelte-1spak4f{box-shadow:0px 4px 8px rgba(0,0,0,.2);transform:translate(0, -2px)}}@media(max-width: 768px){.wrapper.svelte-1spak4f{width:50%;padding:4px}}.content.svelte-1spak4f{position:relative;box-shadow:0px 4px 4px rgba(0,0,0,.1);overflow:hidden;border-radius:16px;transition:all 200ms ease}.emoticon.svelte-1spak4f{position:relative;font-size:24px;margin-bottom:8px;white-space:pre;text-align:center;padding:20px 0}@media(max-width: 1200px){.emoticon.svelte-1spak4f{font-size:2vw}}@media(max-width: 768px){.emoticon.svelte-1spak4f{font-size:4vw;padding:16px 0}}.hover.svelte-1spak4f{position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(255,255,255,.8);opacity:0;transition:opacity 200ms ease}.inner.svelte-1spak4f{padding:16px}.tags.svelte-1spak4f{text-align:left;font-size:18px;color:#000;padding:16px}.tags-header.svelte-1spak4f{font-size:12px;text-transform:uppercase;font-weight:bold;color:#888;letter-spacing:1px}";
	append(document_1.head, style);
}

function create_fragment(ctx) {
	var div7, div6, div1, div0, t0_value = ctx.emoticon.contents.trim(), t0, t1, div5, div4, div2, t3, div3, t4_value = ctx.emoticon.tags.join(', '), t4, dispose;

	return {
		c() {
			div7 = element("div");
			div6 = element("div");
			div1 = element("div");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div5 = element("div");
			div4 = element("div");
			div2 = element("div");
			div2.textContent = "tags";
			t3 = space();
			div3 = element("div");
			t4 = text(t4_value);
			attr(div0, "class", "emoticon svelte-1spak4f");
			attr(div1, "class", "inner svelte-1spak4f");
			attr(div2, "class", "tags-header svelte-1spak4f");
			attr(div4, "class", "tags svelte-1spak4f");
			attr(div5, "class", "hover svelte-1spak4f");
			attr(div6, "class", "content svelte-1spak4f");
			attr(div7, "class", "wrapper svelte-1spak4f");
			dispose = listen(div7, "click", ctx.copyEmoji);
		},

		m(target, anchor) {
			insert(target, div7, anchor);
			append(div7, div6);
			append(div6, div1);
			append(div1, div0);
			append(div0, t0);
			ctx.div0_binding(div0);
			append(div6, t1);
			append(div6, div5);
			append(div5, div4);
			append(div4, div2);
			append(div4, t3);
			append(div4, div3);
			append(div3, t4);
		},

		p(changed, ctx) {
			if ((changed.emoticon) && t0_value !== (t0_value = ctx.emoticon.contents.trim())) {
				set_data(t0, t0_value);
			}

			if ((changed.emoticon) && t4_value !== (t4_value = ctx.emoticon.tags.join(', '))) {
				set_data(t4, t4_value);
			}
		},

		i: noop,
		o: noop,

		d(detaching) {
			if (detaching) {
				detach(div7);
			}

			ctx.div0_binding(null);
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { emoticon, onCopy } = $$props;

  let content

  function copyEmoji () {
    window.getSelection().removeAllRanges()

    var range = document.createRange()
    range.selectNode(content)
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()

    onCopy(emoticon.contents)
  }

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$$invalidate('content', content = $$value);
		});
	}

	$$self.$set = $$props => {
		if ('emoticon' in $$props) $$invalidate('emoticon', emoticon = $$props.emoticon);
		if ('onCopy' in $$props) $$invalidate('onCopy', onCopy = $$props.onCopy);
	};

	return {
		emoticon,
		onCopy,
		content,
		copyEmoji,
		div0_binding
	};
}

class Component extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1spak4f-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, ["emoticon", "onCopy"]);
	}
}

export default Component;